
<div class="header-module">
  <div class="brand-container">
    <img
      src="../../assets/imgs/logo.png"
      alt=""
      class="image"
      (click)="navigateHome()"
    />
  </div>
  <div class="functions-container">
    <div class="group-selections">
      <div class="pop-content-container">
        <mat-form-field>
          <mat-label>Chọn khu vực</mat-label>
          <mat-select (selectionChange)="onChangeGroup($event)">
            <mat-option *ngFor="let group of listGroup" [value]="group.id"  (click)="navigateGroup(group.id)" >{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
</div>
